import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

var firebaseConfig = {
    apiKey: "AIzaSyAKz0wRT6t5HWU4PkqidKO8fAmAfllGLIc",
    authDomain: "vectorpush-96ab7.firebaseapp.com",
    projectId: "vectorpush-96ab7",
    storageBucket: "vectorpush-96ab7.appspot.com",
    messagingSenderId: "36590290609",
    appId: "1:36590290609:web:96f13f9c90ea30504c0490",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = (setTokenFound) => {
    return getToken(messaging, { vapidKey: "BPsUrz7AuKGcV76SqlRTnkBfGV3rOzvhedw9cXoiwNbV-PEca_75s0ks6C4cpmSxGEVzwfXD-JABL45Ej8wOBmQ" })
        .then((currentToken) => {
            if (currentToken) {
                console.log("current token for client: ", currentToken);
                setTokenFound(true);
                return currentToken;
                // Track the token -> client mapping, by sending to backend server
                // show on the UI that permission is secured
            } else {
                console.log("No registration token available. Request permission to generate one.");
                setTokenFound(false);
                // shows on the UI that permission is required
            }
        })
        .catch((err) => {
            return false;
            // catch error while creating client token
        });
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            //            const notificationTitle = payload.data.title;
            //          const notificationOptions = { body: payload.data.body, tag: "/account_summary/" + payload.data.account_number, data: { url: "https://vectorpush-admin.falconsnest.dev/logo192.png", status: "open" } };

            messaging.notificationTitle = payload.data.title;
            messaging.notificationOptions = { body: payload.data.body, tag: "/account_summary/" + payload.data.account_number, data: { url: "https://vectorpush-admin.falconsnest.dev/logo192.png", status: "open" } };
            resolve(payload);
        });
    });
